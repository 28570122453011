import React from 'react';
import styled, { css } from 'styled-components';
import Section from '../../../molecules/Section/Section';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import SuccessHeroStyles from '../../../styles/Components/Hero/SuccessHeroStyles';
import Icon from '../../../styles/atoms/icons';
import HeaderNoMenu from '../../Header/HeaderNoMenu';

const SuccessSpanish = ({ title, body, logo }) => {
  return (
    <SuccessHeroStyles>
      <Section
        className="full-section"
        backgroundImageName="blue-hex"
        innerStyle={containerStyles}
      >
        <LogoContainer>
          <HeaderNoMenu
            background="Charcoal"
            disableLogoNavigation={false}
            isFullWidth={false}
            logo={logo}
          />
          <div className="hero-content">
            <Icon className="icon" id="checkmark" isImage />
            <h1>{title}</h1>
            {body && <p>{body}</p>}
            <div className="action-btns">
              <CallToAction
                link="/spanish"
                variant="secondary"
                value="Volver a la pagina"
              />
            </div>
          </div>
        </LogoContainer>
      </Section>
    </SuccessHeroStyles>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const containerStyles = css`
  padding-top: 0px !important;
`;

export default SuccessSpanish;
